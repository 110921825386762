import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import JusticeBanner from "../components/justiceBanner";

class JusticeWorkingGroup extends Component {
    render() {
        return (
            <Layout headerElement={<JusticeBanner />}>
                <SEO title="Justice Working Group" />
                <section id="section3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <img alt="lawyer" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/scales.png" className="img-fluid p-t-100 p-b-100" />
                            </div>
                            <div className="col-lg-7 mt-5">
                                <h2 className="text-medium" style={{marginTop: 40}}><img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/sepow-small.png" width="40" /> Justice Stategy</h2>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px'}}>
                                    <li style={{marginBottom: 12}}>Access to justice</li>
                                    <li style={{marginBottom: 12}}>The roots of over-policing and surveillance (racial profiling)</li>
                                    <li style={{marginBottom: 12}}>Over-incarceration</li>
                                    <li style={{marginBottom: 12}}>Anti-Black racism in all dimensions of the criminal justice system and corrections arise
from the legacy of enslavement and segregation</li>
                                </ul>
                                <div className="widget-title recommended" style={{textDecoration: 'underline'}}>Recommendations</div>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px', marginTop: 18}}>
                                    <li style={{marginBottom: 12}}>Implement a fully-resourced <b>African Nova Scotian Justice Strategy/ Institute</b> (see Marshall Report) led by African Nova Scotians, including:</li>
                                    <li className="d-block" style={{marginBottom: 12}}>
                                        <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px', marginTop: 18}}>
                                            <li style={{marginBottom: 12}}>Address and correct the disproportionately high rates of African Nova Scotians within the criminal justice system, and ensure equitable and African Nova
Scotian-specific services for African Nova Scotian offenders (including restorative justice, court worker and reintegration programs and services)</li>
                                            <li style={{marginBottom: 12}}>Increase the number of African Nova Scotian justice personnel, including Crown prosecutors, defense counsel and judges; and</li>
                                            <li style={{marginBottom: 12}}>Establish the systematic use of African NS Cultural Impact Assessments</li>
                                            <li style={{marginBottom: 12}}>Create and implement an African Nova Scotian Policing Strategy</li>
                                        </ul>
                                    </li>
                                </ul>
                                <div className="clear"></div>
                                <div className="seperator"><i className="fa fa-gavel"></i></div>
                            </div>
                        </div>

                    </div>
                </section>
            </Layout>
        );
    }
}

export default JusticeWorkingGroup